import React, { useState, useEffect } from "react"
import { useQueryParam } from "gatsby-query-params";
import moment from 'moment';
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import CarouselComponent from '../components/carousel';
import NewsCardModal from '../components/news-card-modal';
import RoundCheckBox from "~/components/round-checkbox"
import PlayUrlContext from "~/context/play-url"
import CarouselPromotionComponent from '../components/carousel-promotion';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 20px;

  div {
    color: var(--font-primary);
  }

  .grid {
    display: grid;
    grid-template-columns: 75% 25%;
  }

  @media screen and (max-width: 769px) {
    display: none;
  }
`

const CardContent = styled.div`
  background: var(--bg-primary);
  border-radius: 6px;
  margin: 10px;

  > img {
    width: 200px;
    height: 200px;
    margin: 0 20px 0 20px;
    border-radius: 10px;
  }

  div {
    font-size: 14px;
    padding-right: 6px;
    color: var(--font-primary);

    .tag {
      font-size: 10px;
      width: fit-content;
      border-radius: 4px;
      padding: 2px;

      &.event {
        color: #fff;
        background-size: 16px;
        background-color: var(--tag-green);
      }

      &.new {
        color: #fff;
        background-size: 16px;
        background-color: var(--tag-pink);
      }

      &.promotion {
        color: #fff;
        background-size: 16px;
        background-color: var(--tag-orange);
      }

      &.hot {
        color: #fff;
        background-size: 16px;
        background-color: #dc143c;
      }
    }

    .detail {
      p {
        color: var(--font-primary);
      }
    }

    ul {
      font-size: 10px;
      color: var(--font-primary);

      li:before {
        content: "-";
      }
    }
  }
`

const WrapperMobile = styled.div`
  position: relative;
  padding: 20px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

const HeadMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  color: var(--font-primary);

  h1 {
    color: var(--font-primary);
  }
`

const Content = styled.div`
  color: var(--font-primary);

  .card-content {
    padding: 20px;
    background: var(--bg-third);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    text-align: center;

    img {
      border-radius: 10px;
    }

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  .description {
    color: var(--font-primary);
    margin: 10px;
  }
`

const WrapperFilter = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 11;

  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.34);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.9px);
    -webkit-backdrop-filter: blur(9.9px);
  }

  .boxInner {
    width: 285px;
    background: var(--bg-primary) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 10;
    padding: 30px;

    @media screen and (max-width: 768px) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100vh;
    }
  }
`


const ContentCard = styled.div`
  position: relative;
  .image-card {
    border-radius: 24px;
    position: relative;
  }

  .logo-image {
    position: absolute; 
    left: 0;
    bottom: 0;
  }
`


const ButtonClose = styled.div`
  position: fixed;
  right: 20px;

  .btn-close {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-1 {
    border: 1px solid var(--color-primary);
    rotate: 45deg;
    width: 20px;
    position: absolute;
  }

  .line-2 {
    border: 1px solid var(--color-primary);
    rotate: -45deg;
    width: 20px;
    position: absolute;
  }
`

const EventPage = ({
  data: {
    allMarkdownRemark,
    markdownRemark: {
      frontmatter
    },
    trending,
    promotion,
    common,
    word
  },
  pageContext
}) => {
  const _common = common.edges
    .find(
      item => item.node.file
        .split(".")[1]
        .replace("/", "") === pageContext.langKey
    ).node
  const _word = word.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === pageContext.langKey).node
  const eventList = allMarkdownRemark.edges
    .filter(item => item.node.file.split("/")[1] === pageContext.langKey)
    .map(item => item.node)
  const trendingList = trending.edges
    .filter(item => item.node.file.split("/")[1] === pageContext.langKey)
    .map(item => item.node)
  const promotionList = promotion.edges
    .filter(item => item.node.file.split("/")[1] === pageContext.langKey)
    .map(item => item.node)

  const listMonthDate = eventList.map(
    ({ frontmatter: { end_date } }) => [
      end_date.substring(3),
    ]
  )
  const [data, setData] = useState([])
  const listEvent = ['event', 'news', 'promotion']
  const list = [...new Set([].concat.apply([], [...listMonthDate]))]
  const [newRenderData, setNewRenderData] = useState([...eventList])
  const [listSelected, setListSelected] = useState([...list])
  const [detailEvent, setDetailEvent] = useState([])
  const [isShowDetail, setIsShowDetail] = useState(false)
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [currentFilter, setCurrentFilter] = useState("All Year")
  const [listEventSelect, setListEventSelect] = useState([...listEvent])
  const { webConfig, gameData, centralList, news } = React.useContext(PlayUrlContext);

  const querySort = useQueryParam('sort');

  const handleShowDetail = () => setIsShowDetail(!isShowDetail)

  const _setDetailEvent = (value) => setDetailEvent(value)

  const checkMonth = (value) => {
    const a = moment(value).format('MMMM YYYY');
    return a;
  }

  const newsData = news ? news : []

  useEffect(() => {
    const fetchData = () => {
      setData(gameData)
    }
    fetchData();
  }, [gameData])

  useEffect(() => {
    if (querySort) {
      const newRender = eventList.filter(i => i.frontmatter.type.toLowerCase() === querySort.toLowerCase())
      const newData = [...new Set([].concat.apply([], [...newRender]))]
      setNewRenderData(newData)
      setListEventSelect([querySort])
    }
  }, [querySort])

  const toggleShowFilter = () => setIsShowFilter(!isShowFilter);

  const handleCheckEvent = ({ target: { className } }) => {
    renderEvent(className)
  }

  const renderEvent = (value) => {
    if (value === "ALL NEWS") {
      if (listEvent.length === listEventSelect.length) {
        setListEventSelect([])
        setNewRenderData([])
      } else {
        setListEventSelect([...listEvent])
        setNewRenderData([...eventList])
      }
    } else {
      const newSelected = listEventSelect.includes(value)
        ? listEventSelect.filter(item => item !== value)
        : [...listEventSelect, value]
      const newRender = newSelected.map(items => {
        const newList = eventList.filter(i => i.frontmatter.type.toLowerCase() === items.toLowerCase())
        return newList;
      })

      const newData = [...new Set([].concat.apply([], [...newRender]))]
      setNewRenderData(newData)
      setListEventSelect([...newSelected])
    }
  }

  const handleCheck = ({ target: { className } }) => {
    if (className === "ALL") {
      setCurrentFilter("ALL")
      if (list.length === listSelected.length) {
        setListSelected([])
      } else {
        setListSelected([...list])
      }
    } else {
      const newSelected = listSelected.includes(className)
        ? listSelected.filter(item => item !== className)
        : [...listSelected, className]

      const curFilter = moment(className).format('YYYY')
      setCurrentFilter(curFilter)
      setListSelected([...newSelected])
    }
  }
  return (
    <Layout pageContext={pageContext}>
      <Seo
        // title={frontmatter.metadata.metaTitle}
        // description={frontmatter.metadata.metaDescription}
        title='AMBPLAY'
        description='AMBPLAY'
        favicon_logo={`${webConfig && webConfig.favicon ? webConfig.favicon : _common.frontmatter.favicon_logo}`}
      />
      <Wrapper>
        <div className='grid'>
          <CarouselComponent list={centralList.trendings ? centralList.trendings : data} headerText={_word.frontmatter.trending_now} />
          <CarouselPromotionComponent list={centralList.promotions ? centralList.promotions : data} headerText={_word.frontmatter.promotion} />
        </div>
        {/* <div className='relative flex justify-between mt-10'>
          <div className='headText'>{_word.frontmatter.news}</div>
          <div className='cursor-pointer' onClick={() => toggleShowFilter()}>
            {_word.frontmatter.sort_by} : {currentFilter}
          </div>
          <WrapperFilter className={`${isShowFilter ? 'flex' : 'hidden'}`}>
            <div
              className='boxInner'
              style={{
                paddingBottom: "24px",
                borderBottom: "1px solid #eee",
              }}
            >
              <div style={{ padding: '0px 0px 20px' }}>
                <RoundCheckBox
                  text={`All News`}
                  handleCheck={handleCheckEvent}
                  check={listEventSelect.length === listEvent.length}
                  typeClass={`ALL NEWS`}
                  isFront={true}
                  isSome={
                    listEventSelect.length < listEvent.length && listEventSelect.length > 0
                  }
                />
              </div>
              <hr />
              {listEvent.map((item, index) => {
                return (
                  <div key={index}>
                    <div style={{ fontSize: "10px" }}>
                      <RoundCheckBox
                        text={item}
                        handleCheck={handleCheckEvent}
                        check={listEventSelect.some(data => data === item)}
                        typeClass={item}
                        isFront={true}
                      />
                    </div>
                  </div>
                )
              })}
              <div style={{ padding: '20px 0px 10px' }}>
                <hr />
                <RoundCheckBox
                  text={`All Year`}
                  handleCheck={handleCheck}
                  check={listSelected.length === list.length}
                  typeClass={`ALL`}
                  isFront={true}
                  isSome={
                    listSelected.length < list.length && listSelected.length > 0
                  }
                />
              </div>
              <hr />
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display:
                          index !== 0 &&
                            item.slice(-4, item.length) === list[index - 1].slice(-4, list[index - 1].length)
                            ? "none"
                            : "block",
                        marginTop: "12px",
                        fontSize: "14px",
                      }}
                    >
                      {item.slice(-4, item.length)}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      <RoundCheckBox
                        text={item.slice(0, -5)}
                        handleCheck={handleCheck}
                        check={listSelected.some(data => data === item)}
                        typeClass={item}
                        isFront={true}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </WrapperFilter>
        </div> */}
        {/* {listSelected && listSelected.map((month, index) => ( */}
          <div className='block my-5'>
            {/* <div>{month}</div> */}
            <div className='flex-1'>
              {/* {newsData.filter(i => checkMonth(i.date) === month).map((items, idx) => ( */}
              {newsData.map((items, idx) => (
                <div>
                  <div>{items.date}</div>
                <CardContent className="relative flex overflow-hidden card-content" key={idx}>
                  {items.games.map((v, i) => (
                    <div>
                    <ContentCard>
                        <img className="image-card" src={`${v.ambplayImgUrl ? v.ambplayImgUrl.card : ''}`}/>
                        <img className="logo-image" src={`${v.ambplayImgUrl ? v.ambplayImgUrl.logo : ''}`} />
                    </ContentCard>
                    <div className="font-bold">{v.prefix}</div>
                    <div
                      className={`tag ${v.kind.toLowerCase()}`}
                    >
                      {_word.frontmatter[v.kind.toLowerCase()]}
                    </div>
                    <div
                      className="detail"
                      dangerouslySetInnerHTML={{
                        __html: items.html,
                      }}
                    ></div>
                  </div>
                  ))}
                </CardContent>
                </div>
              ))}
            </div>
            <hr className='mt-5' />
          </div>
        {/* ))} */}
      </Wrapper>
      <WrapperMobile>
        <HeadMobile>
          <h1>{_word.frontmatter.news}</h1>
          {/* <div className='cursor-pointer' onClick={() => toggleShowFilter()}>
            {_word.frontmatter.news} : {currentFilter}
          </div> */}
          {/* <WrapperFilter className={`${isShowFilter ? 'flex' : 'hidden'}`}>
            <div
              className='boxInner'
              style={{
                paddingBottom: "24px",
                borderBottom: "1px solid #eee",
              }}
            >
              <ButtonClose>
                <button onClick={toggleShowFilter}>
                  <div className='btn-close'>
                    <div className='line-1'></div>
                    <div className='line-2'></div>
                  </div>
                </button>
              </ButtonClose>
              <div style={{ padding: '0px 0px 20px', marginTop: "40px" }}>
                <RoundCheckBox
                  text={`ALL`}
                  handleCheck={handleCheck}
                  check={listSelected.length === list.length}
                  typeClass={`ALL`}
                  isFront={true}
                  isSome={
                    listSelected.length < list.length && listSelected.length > 0
                  }
                />
              </div>
              <hr />
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display:
                          index !== 0 &&
                            item.slice(-4, item.length) === list[index - 1].slice(-4, list[index - 1].length)
                            ? "none"
                            : "block",
                        marginTop: "24px",
                        fontSize: "14px",
                      }}
                    >
                      {item.slice(-4, item.length)}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      <RoundCheckBox
                        text={item.slice(0, -5)}
                        handleCheck={handleCheck}
                        check={listSelected.some(data => data === item)}
                        typeClass={item}
                        isFront={true}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </WrapperFilter> */}
        </HeadMobile>
        <Content>
          {/* {listSelected && listSelected.map((month, index) => ( */}
          {newsData.map((items, idx) => (
            <div className='block my-5'>
              <div>{items.date}</div>
              <div className='grid grid-cols-2 gap-4'>
                {/* {newRenderData.filter(i => checkMonth(i.frontmatter.start_date) === month).map((items, idx) => ( */}
                {items.games.map((v, i) => (
                // {newsData.map((items, idx) => (
                  <div className="relative block overflow-hidden card-content"
                    key={idx}
                  >
                    {/* <div>{items.date}</div> */}
                    {/* {items.games.map((v, i) => ( */}
                    {/* <button onClick={() => [_setDetailEvent(items), handleShowDetail()]}> */}
                    <button>
                      <ContentCard>
                          <img className="image-card" src={`${v.ambplayImgUrl ? v.ambplayImgUrl.card : ''}`}/>
                          <img className="logo-image" src={`${v.ambplayImgUrl ? v.ambplayImgUrl.logo : ''}`} />
                      </ContentCard>
                      <div className='description'>
                      <div className="py-3 font-bold">{v.prefix}</div>
                        <div
                          style={{
                            color: 'var(--gray)',
                            fontSize: '10px',
                            position: 'absolute',
                            bottom: '10px',
                            margin: 'auto',
                            width: '100%',
                            left: '0',
                          }}
                        >
                          {/* {_word.frontmatter[items.frontmatter.type.toLowerCase()]} */}
                        </div>
                      </div>
                    </button>
                    {/* ))} */}
                  </div>
                ))}
              </div>
              <hr className='mt-5' />
            </div>
            ))}
        </Content>
      </WrapperMobile>
      {isShowDetail && (
        <NewsCardModal detailEvent={detailEvent} handleShowDetail={handleShowDetail} isShowDetail={isShowDetail} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query EventPageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: {
        collection: { eq: "event" }
        frontmatter: { is_active: { eq: true } }
      }
      sort: { fields: frontmatter___start_date, order: DESC }
    ) {
      edges {
        node {
          file
          html
          frontmatter {
            type
            title
            start_date(formatString: "DD MMMM YYYY")
            end_date(formatString: "DD MMMM YYYY")
            thumbnail
          }
        }
      }
    }

    markdownRemark(
      frontmatter: {templateKey: {eq: "event"}}
      fields: {langKey: {eq: $langKey}}
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
      }
      fields {
        langKey
      }
    }

    trending: allMarkdownRemark(
      filter: {
        collection: {eq: "trending"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            Icon
            banner_text
            button_text
          }
        }
      }
    }

    promotion: allMarkdownRemark(
      filter: {
        collection: {eq: "promotion"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            name
            image
          }
        }
      }
    }

    iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
        edges {
          node {
            id
            frontmatter {
              question
              sort
              sun
              gift
              play
            }
          }
        }
      }

    common:allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            favicon_logo
          }
        }
      }
    }

    word: allMarkdownRemark(filter: {collection: {eq: "word"}}) {
      edges {
        node {
          file
          frontmatter {
            search
            play
            home
            game
            news
            download
            trending_now
            promotion
            new_game
            hot_game
            game_category
            slot
            card
            arcade
            fishing
            board
            recommend_game
            featured
            chat
            event
            game_release
            sort_by
            hot
            hit
            recommend
            new
            show_more
            play_now
            try_now
          }
        }
      }
    }
  }
`

export default EventPage
